import React from "react";
import { StaticQuery, graphql} from "gatsby";

import Layout from "../components/layout";

const Rules = () => (
  <div className='rules-bg'>
    <Layout>
      <StaticQuery
        query={graphql`
            query rulesQuery {
              allMarkdownRemark(filter: {frontmatter: {title: {eq: "Rules"}}}) {
                edges {
                  node {
                    html
                  }
                }
              }
            }
          `}
        render={data => {
          return (
            <div style={{
              height: "100%",
            }}>
              <h1>RULES</h1>
              <div className='body' style={{fontSize: 18,}}>
                <div dangerouslySetInnerHTML={{ __html: data.allMarkdownRemark.edges[0].node.html }} />
              </div>
            </div>
          );
        }}
      />
    </Layout>
  </div>
);

export default Rules;
